import Vue from 'vue'
import Vuex from 'vuex'
import getters  from './getters'
import user from './modules/user'
import language from './modules/language'
import NavManage from './modules/NavManage'
import OrderCenter from "./modules/OrderCenter"
import staging from "./modules/staging.js"

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    user,
    language,
    NavManage,
    OrderCenter,
    staging
  },
  getters
})
