/*
 * @Description:
 * @Author: luis
 * @Date: 2023-11-24
 * @LastEditors: luis
 * @FilePath: \merchantsys-vue\src\utils\vHover.js
 */
import Vue from 'vue'
Vue.directive('hover', {
  bind(el, binding, vnode) {
    // 在绑定时
    const svgElement = [...el.querySelectorAll('svg')]
    el.addEventListener('mouseenter', () => {
      svgElement.map((item) => {
        item.classList.add('hover-class')
      })
    })
    el.addEventListener('mouseleave', () => {
      svgElement.map((item) => {
        item.classList.remove('hover-class')
      })
    })
  }
})
