<!--
 * @Description: 
 * @Author: luis
 * @Date: 2023-11-24
 * @LastEditors: luis
 * @FilePath: \merchantsys-vue\src\components\Message\index.vue
-->
<template>
  <div :class="['messagePage', { messagePageShow: showModel }]"   :style="{
            '--background-color': this.backgroundColor,
        }" >
    <div class="content">
      <div class="icon" v-if="icon !== 'none'">
        <SvgIcon :path="icon" :color="iconColor[icon]"></SvgIcon>
      </div>
      <div class="msg">
        <span>{{ msg }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import SvgIcon from '@/components/SvgIcon'
export default {
  components: { SvgIcon },
  props: {
    icon: {
      type: String,
      default: 'warn'
    },
    msg: {
      type: String,
      default: '未知錯誤!'
    },
    duration: {
      type: Number,
      default: 2000
    },
    backgroundColor: {
      type: String,
      default: 'rgba(29, 25, 25, 0.6)'
    }
  },
  data() {
    return {
      iconColor: {
        succeed: '#3DB2FF',
        failed: '#FF8080',
        warn: '#FFCE76'
      },
      showModel: false
    }
  },
  mounted() {
    let t = setTimeout(() => {
      this.showModel = true
    })
    t = setTimeout(() => {
      this.showModel = false
      clearTimeout(t)
    }, this.duration)
  }
}
</script>

<style lang="scss" scoped>
.messagePage {
    position: fixed;
    top: 47%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
    opacity: 0;
    transition: all linear 0.1s;
    .content{
        width: auto;
        padding: 14px 18px;
        border-radius: 4px;
   
        background: var(--background-color);
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .msg{
            letter-spacing: 0.0025em;
            font-size: 14px;
            color: #FFFFFF;
        }
        .icon{
            width: 20px;
            height: 20px;
            margin-right: 10px;
        }
    }
    .icon {
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }
  
}
.messagePageShow {
  top: 50%;
  opacity: 1;
  transition: all linear 0.2s;
}
</style>
