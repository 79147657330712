/*
 * @Description:
 * @Author: luis
 * @Date: 2023-11-18
 * @LastEditors: luis
 * @FilePath: /thmall-operation/src/main.js
 */
import "@/plugins/index";
import "@/style/reset.scss";
import i18n from "@/utils/i18n/index";
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
// 全局过滤器
import '@/filters'
// 自定义指令权限控制 v-permission
import "@/router/permission";
// 自定义指令hover样式控制 v-hover
import "@/components/Message/message";
// 数字格式化，千分号+2位小数
import { formatNumber } from "@/utils/util";
import inputFilter from '@/utils/InputFilter'
import "@/utils/vHover";

import * as axios from "@/service/request";

import viewProInH5 from '@/components/viewProInH5'

import "@/style/common.scss";
// avue
import Avue from "@smallwei/avue/lib/avue.min.js";
import "@smallwei/avue/lib/index.css";
Vue.use(Avue, {
  size: "small",
  menuType: "text",
});

// 富文本编辑器---avue
import AvueUeditor from '@/sourceCode/avue-plugin-ueditor/packages/index' // 有源码修改，所以 依赖放在本地
Vue.use(AvueUeditor);

Vue.directive('inputFilter', inputFilter)

Vue.prototype.$http = axios;
Vue.prototype.$formatNumber = formatNumber;
Vue.prototype.$viewProInH5 = viewProInH5

Vue.config.productionTip = false;

export const app = new Vue({
  i18n,
  router,
  store,
  beforeCreate() {
    Vue.prototype.$bus = this;
  },
  render: (h) => h(App),
}).$mount("#app");
