/*
 * @Description:
 * @Author: yanxing
 * @Date: 2023-11-18
 * @LastEditors: yanxing
 * @FilePath: /merchantsys-vue/src/service/request.js
 */
import apis from './apis'
import axiosInstance from './axios'

/**
 * @description: // 获取工作台数据总览、待审核、待发货、待售后总数
 * @return {*}
 */
export async function queryBackendWorkbenchInfo(payload) {
    // { params: payload }
    try {
      const data = await axiosInstance.get(apis.queryBackendWorkbenchInfo)
      return data
    } catch (e) {
      console.log(e)
    }
  }

/**
 * @description: // 待售后table表格数据
 * @return {*}
 */
export async function queryStayAfterSales(payload) {
  // { params: payload }
  try {
    const data = await axiosInstance.get(apis.queryStayAfterSales,  { params: payload })
    return data
  } catch (e) {
    console.log(e)
  }
}

/**
 * @description: // 待发货table表格数据
 * @return {*}
 */
export async function queryToBeShippedInfo(payload) {
  // { params: payload }
  try {
    const data = await axiosInstance.get(apis.queryToBeShippedInfo,  { params: payload })
    return data
  } catch (e) {
    console.log(e)
  }
}