/*
 * @Description:
 * @Author: yanxing
 * @Date: 2024-4-30
 * @LastEditors: yanxing
 * @FilePath: \merchantsys-vue\src\store\modules\user.js
 */
import {queryBackendWorkbenchInfo} from  "@/service/staging.js"

const state = {
    stagingData: {}, // 获取工作台数据总览、待审核、待发货、待售后数据
   
  }
  
  const getters = {
    
  }
  
  const mutations = {
    MUT_STAGINGDATA: (state, payload) => {
        state.stagingData = payload
    },
 
  }
  
  const actions = {
    ACT_STAGINGDATA: async({ commit }, payload) => {
        let res = await queryBackendWorkbenchInfo() 
        if(res?.code == 200){
            commit('MUT_STAGINGDATA', res.data)
        } else {
            commit('MUT_STAGINGDATA', {})
        }
    },
  
  }
  
  export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
  }
  