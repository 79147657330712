import Vue from "vue";
import h5View from "./index.vue";
import router from "@/router";

let h5ViewConstructor = Vue.extend(h5View);
let instance;
const viewProInH5 = function (spuId) {
  const isMountDom = document.querySelector("#_view-proin-h5");

  if (isMountDom) {
    instance.open(spuId);
  } else {
    instance = new h5ViewConstructor({
      data: {
        router,
        spuId,
      },
    });
    const dom = document.createElement("div");

    dom.id = "_view-proin-h5";

    dom.appendChild(instance.$mount().$el);

    document.body.appendChild(dom); //this.$el拿到组件实际上的dom，把他挂载到body上
  }
};

export default viewProInH5;
