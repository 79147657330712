/*
 * @Description:
 * @Author: luis
 * @Date: 2023-11-18
 * @LastEditors: luis
 * @FilePath: \merchantsys-vue\src\store\modules\user.js
 */




  const state = {
    // 导航管理
    editId: '', // 编辑导航版本 id
  }
  
  const getters = {
    
  }
  
  const mutations = {
    MTS_EDITID: (state, payload) => {
      state.editId = payload
    },
   
  }
  
  const actions = {
    ACS_EDITID({ commit }, payload) {
      commit('MTS_EDITID', payload)
    }
  }
  
  export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
  }
  