/*
 * @Description:
 * @Author: luis
 * @Date: 2023-11-18
 * @LastEditors: luis
 * @FilePath: \thmall-operation\src\store\modules\user.js
 */
import Vue from 'vue'
const state = {
  userName: '',
  userInfo: localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : null,
  linkTypeData: {},
  // 营销中心 -> 优惠劵管理 -> 新建优惠劵 -> 适用范围 -> 指定商品 -> 商品列表-自定义
  sgaAddCouponTypeData: {},
  // 营销中心 -> 优惠劵管理 -> 新建优惠劵 -> 发放场景 -> 购买指定商品 -> 商品列表-自定义
  dspspAddCouponTypeData: {},
  // 营销中心 -> 秒杀管理 -> 新建秒杀活动 -> 活动商品
  SeckillProductsTypeData: {},
  // 推荐商品管理 -> 自定义列表
  rpmLinkTypeData: {},
  // 导航管理跳转链接类型数据
  navlinkTypeData: {},
  searchTypeData: [],
  // 运营位跳转链接类型数据
  operationLinkTypeData: {},
  // 推荐商品勾选数据
  selectedRecommendedProducts: sessionStorage.getItem('selectedRecommendedProducts') == null ?[] : JSON.parse(sessionStorage.getItem('selectedRecommendedProducts')),
  // 砍价管理 -> 新建/编辑砍价活动 -> 添加商品
  BMEI: [],
  // 砍价管理 -> 新建/编辑砍价活动 -> 添加优惠劵
  BPC: [],
  // 砍价管理页面选中row
  BargainingManagementActivRow:  sessionStorage.getItem('BargainingManagementActivRow') == null ?{} : JSON.parse(sessionStorage.getItem('BargainingManagementActivRow')),
   // 邀请有礼->添加活动->跳转链接类型数据
   InviteRewardlinkTypeData: {},
}

const getters = {
  // 获取 userName 的值
  getUserName: (state) => {
    return state.userName
  }
}

const mutations = {
  MUT_SELECTEDRECOMMENDEDPRODUCTS:(state, payload) => {
   
    state.selectedRecommendedProducts = payload
     sessionStorage.setItem('selectedRecommendedProducts', JSON.stringify(payload))
  },
  MUT_BARGAININGMANAGEMENTACTIVROW:(state, payload) => {
   
    state.BargainingManagementActivRow = payload
     sessionStorage.setItem('BargainingManagementActivRow', JSON.stringify(payload))
  },
  MT_USERNAME: (state, payload) => {
    state.userName = payload
  },
  SET_USERINFO(state, payload) {
    localStorage.setItem('userInfo', JSON.stringify(payload))
    state.userInfo = payload
  },

  SET_SGAADDCOUPONTYPEDATA: (state, payload) => { // 添加
    const type = payload.type
    state.sgaAddCouponTypeData[type] = payload.selection || null
  },
  ClEAR_SGAADDCOUPONTYPEDATA: (state, payload) => { // 清空
    state.sgaAddCouponTypeData = {}
  },
  
  SET_BMEI: (state, payload) => { // 添加
    state.BMEI = payload.selection || null
  },
  ClEAR_BMEI: (state, payload) => { // 清空
    state.BMEI = []
  },

  SET_BPC: (state, payload) => { // 添加
    state.BPC = payload.selection || null
  },
  ClEAR_BPC: (state, payload) => { // 清空
    state.BPC = []
  },

  SET_DSPSPADDCOUPONTYPEDATA: (state, payload) => { // 添加
    const type = payload.type
    state.dspspAddCouponTypeData[type] = payload.selection || null
  },
  ClEAR_DSPSPADDCOUPONTYPEDATA: (state, payload) => { // 清空
    state.dspspAddCouponTypeData = {}
  },
  
  SET_SECKILLPRODUCTSTYPEDATA: (state, payload) => { // 添加
    const type = payload.type
    state.SeckillProductsTypeData[type] = payload.selection || null
  },
  ClEAR_SECKILLPRODUCTSTYPEDATA: (state, payload) => { // 清空
    state.SeckillProductsTypeData = {}
  },


  SET_RPMLINKTYPEDATA: (state, payload) => { // 添加
    const type = payload.type
    state.rpmLinkTypeData[type] = payload.selection || null
  },
  ClEAR_RPMLINKTYPEDATA: (state, payload) => { // 清空
    state.rpmLinkTypeData = {}
  },
  SET_LINKTYPEDATA: (state, payload) => {
    const type = payload.type
    state.linkTypeData[type] = payload.selection || null
  },
  SET_REMOVELINKTYPEDATA: (state, payload) => {
    state.linkTypeData = {}
  },
  SET_NAVLINKTYPEDATA: (state, payload) => {
    let index = payload.index + ''
    let type = payload.type
    // 检查 navlinkTypeData[index] 是否存在，不存在则初始化为空对象
    if (!state.navlinkTypeData[index]) {
      Vue.set(state.navlinkTypeData, index, {})
    }
    // 设置深层次嵌套对象的属性
    Vue.set(state.navlinkTypeData[index], type, payload.selection || null)
  },
  DEL_NAVLINKTYPEDATA: (state, payload) => {
    const index = payload.index + ''
    let obj = state.navlinkTypeData[index]
    if (obj) {
      Vue.delete(state.navlinkTypeData, index)
    }
  },
  CLEAR_NAVLINKTYPEDATA: (state, payload) => {
    state.navlinkTypeData = {}
  },
  
  // 邀请有礼
  SET_INVITEREWARDLINKTYPEDATA: (state, payload) => {
    let index = payload.index + ''
    let type = payload.type
    // 检查 InviteRewardlinkTypeData[index] 是否存在，不存在则初始化为空对象
    if (!state.InviteRewardlinkTypeData[index]) {
      Vue.set(state.InviteRewardlinkTypeData, index, {})
    }
    // 设置深层次嵌套对象的属性
    Vue.set(state.InviteRewardlinkTypeData[index], type, payload.selection || null)
  },
  DEL_INVITEREWARDLINKTYPEDATA: (state, payload) => {
    const index = payload.index + ''
    let obj = state.InviteRewardlinkTypeData[index]
    if (obj) {
      Vue.delete(state.InviteRewardlinkTypeData, index)
    }
  },
  CLEAR_INVITEREWARDLINKTYPEDATA: (state, payload) => {
    state.InviteRewardlinkTypeData = {}
  },

  // 运营位
  SET_OPERATIONLINKTYPEDATA: (state, payload) => {
    let index = payload.index + ''
    let type = payload.type
    // 检查 navlinkTypeData[index] 是否存在，不存在则初始化为空对象
    if (!state.operationLinkTypeData[index]) {
      Vue.set(state.operationLinkTypeData, index, {})
    }
    // 设置深层次嵌套对象的属性
    Vue.set(state.operationLinkTypeData[index], type, payload.selection || null)
  },

  REMOVE_OPERATIONLINKTYPEDATA: (state, payload) => {
    state.operationLinkTypeData = {}
  },

  DEL_OPERATIONLINKTYPEDATA: (state, payload) => {
    const index = payload.index + ''
    let obj = state.operationLinkTypeData[index]
    if (obj) {
      Vue.delete(state.operationLinkTypeData, index)
    }
  },

  SET_SEARCHLINKTYPEDATA: (state, payload) => {
    
    let index = payload.index + ''
    let type = payload.type
    // 检查 navlinkTypeData[index] 是否存在，不存在则初始化为空对象
    if (!state.searchTypeData[index]) {
      Vue.set(state.searchTypeData, index, {})
    }
    // 设置深层次嵌套对象的属性
    Vue.set(state.searchTypeData[index], type, payload.selection || null)
  }
}

const actions = {
  AT_USERNAME({ commit }, payload) {
    commit('MT_USERNAME', payload)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
