import Vue from 'vue';

Vue.filter('formatNoData', function (value) {
    if (value === '' || value === undefined){
       return '无'
    } else {
       return value
    }
});

Vue.filter('addFormat', function(value){
   if(value){
      return '$' + value.toFixed(2)
   }else{
      return '-' // 如果后端返回的是0就，就返回'-'
   }
})