/*
 * @Description:
 * @Author: luis
 * @Date: 2024-1-26
 * @LastEditors: luis
 * @FilePath: \merchantsys-vue\src\store\modules\OrderCenter.js
 */

import {  queryStatusInfo } from '@/service/request'
const state = {
    // 订单管理
    OrderDetailsData: {}, // 订单详情数据
    isUpdateOrder: false, // 是否修改订单金额
    userInfor: {}, // 用户信息
    OrderDetailsProps: sessionStorage.getItem('OrderDetailsProps') == null ? {
      companiesData: [],
      activeRow: {}
    } : JSON.parse(sessionStorage.getItem('OrderDetailsProps'))
  }
  
  const getters = {
    // 订单管理
  
  }
  
  const mutations = {
    // 订单管理
    MUT_ORDERDETAILSDATA: (state, payload) => {
          state.OrderDetailsData = payload
    },
    MUT_ISUPDATEORDER: (state, payload) => {
      state.isUpdateOrder = payload
    },
    MUT_USERINFOR:(state, payload) => {
      state.userInfor = payload
    },
    MUT_ORDERDETAILSPROPS:(state, payload) => {
      if(payload?.companiesData) state.OrderDetailsProps.companiesData = payload.companiesData
      if(payload?.activeRow) state.OrderDetailsProps.activeRow = payload.activeRow
       sessionStorage.setItem('OrderDetailsProps', JSON.stringify({...state.OrderDetailsProps, ...payload}))
    },
  }
  
  const actions = {
   
    ACT_ISUPDATEORDER: ({ commit }, payload) => {
       commit('MUT_ISUPDATEORDER', payload)
    },
    ACT_USERINFOR: async ({ commit }, payload) => {
      let res = await  queryStatusInfo()
      if(res?.code == 200){
        commit('MUT_USERINFOR', res.data)
      } else {
        commit('MUT_USERINFOR', {})
      }
     
   },
  }
  
  export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
  }
  