const clearNoNum = (value) => {
  value = value.replace(/[^\d.]/g, '') // 清除"数字"和"."以外的字符
  value = value.replace(/^\./g, '') // 验证第一个字符是数字而不是字符
  value = value.replace(/\.{2,}/g, '.') // 只保留第一个.清除多余的
  value = value.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.')
  value = value.replace(/^(-)*(\d+)\.(\d\d).*$/, '$1$2.$3') // 只能输入两个小数
  if (value.indexOf('.') < 0 && value !== '') {
    value = parseFloat(value)
  }
  return value
}

const integerOnly = (value) => { // 整数 大于零  正整数
  value = value.replace(/[^\d]/g, '') // 清除"数字"以外的字符
  if (value.indexOf('.') < 0 && value !== '') {
    value = parseFloat(value)
  }
  return value
}

const stringUtils = {
  clearNoNum: clearNoNum, // 只允许输入金额
  integerOnly // 只允许输入整数 且大于0
}

export default stringUtils
